@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");
@import url(../../node_modules/@csstools/normalize.css/opinionated.css);
/*** Taille définies comme telles dans le cdh ***/
/*** Progress ***/
/*** Range ***/
/********* Corpus ************/
html {
  background-color: black;
  touch-action: none;
  font-family: "Oswald", Helvetica, Arial, Open-Sans, sans-serif;
  font-weight: 700;
  color: white;
}

html, body, #corpsVisionneuse, .bodyPhoto {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

header, footer {
  height: 8vh;
  margin-bottom: -8vh;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  padding: 0.5vh 0.5vw;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Pour masquer les débordements */
  overflow: hidden;
  background-color: rgba(28, 28, 27, 0.8);
}

.hidden {
  display: none;
}

/********** Boutons **************/
.btn {
  background-color: #58C2E2;
  background-image: linear-gradient(to bottom, #58C2E2, #58C2E2 40%, #58C2E2 60%, #58C2E2);
  color: white;
  border: 0.1vh solid #58C2E2;
  border-radius: 0.5em;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s linear, background-image 0.2s linear;
  min-height: 2vh;
  line-height: 2vh;
  padding: 0.5vh 0.5vw;
}

.btn:active, .btn:hover, .btn.btnActive {
  background-color: #479EB2;
  background-image: linear-gradient(to bottom, #479EB2, #479EB2 40%, #479EB2 60%, #479EB2);
  color: white;
}

.btn.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.btn.changer {
  background-color: #7E8696;
  background-image: url("../img/selection-image.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  margin: 0 0.25vw 0 0.5vw;
}

.btn.changer:hover, .btn.changer:active, .btn.changer.btnMenuActive {
  background-color: #61646b;
}

.btn.changer.flip {
  transform: rotateY(180deg);
}

.btn.comparer {
  background-color: #7E8696;
  background-image: url("../img/comparer.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  margin: 0 0.5vw 0 0.25vw;
}

.btn.comparer:hover, .btn.comparer:active, .btn.comparer.btnMenuActive {
  background-color: #61646b;
}

.btn.comparer.flip {
  transform: rotateY(180deg);
}

.btn.toLeft {
  background-color: #7E8696;
  background-image: url("../img/gauche-date.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  border-radius: 50%;
}

.btn.toLeft:hover, .btn.toLeft:active, .btn.toLeft.btnMenuActive {
  background-color: #61646b;
}

.btn.toLeft.flip {
  transform: rotateY(180deg);
}

.btn.toRight {
  background-color: #7E8696;
  background-image: url("../img/gauche-date.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  transform: scale(-1, 1);
  border-radius: 50%;
}

.btn.toRight:hover, .btn.toRight:active, .btn.toRight.btnMenuActive {
  background-color: #61646b;
}

.btn.toRight.flip {
  transform: rotateY(180deg);
}

.btn.toMinus {
  background-color: #7E8696;
  background-image: url("../img/diminuer.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  border-radius: 50%;
}

.btn.toMinus:hover, .btn.toMinus:active, .btn.toMinus.btnMenuActive {
  background-color: #61646b;
}

.btn.toMinus.flip {
  transform: rotateY(180deg);
}

.btn.toPlus {
  background-color: #7E8696;
  background-image: url("../img/agrandir.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  border-radius: 50%;
}

.btn.toPlus:hover, .btn.toPlus:active, .btn.toPlus.btnMenuActive {
  background-color: #61646b;
}

.btn.toPlus.flip {
  transform: rotateY(180deg);
}

.btn.save {
  background-color: #7E8696;
  background-image: url("../img/telecharger.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
}

.btn.save:hover, .btn.save:active, .btn.save.btnMenuActive {
  background-color: #61646b;
}

.btn.save.flip {
  transform: rotateY(180deg);
}

.btn.refresh {
  background-color: #7E8696;
  background-image: url("../img/rafraichir.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
}

.btn.refresh:hover, .btn.refresh:active, .btn.refresh.btnMenuActive {
  background-color: #61646b;
}

.btn.refresh.flip {
  transform: rotateY(180deg);
}

.btn.mail {
  background-color: #7E8696;
  background-image: url("../img/envoi-message.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
}

.btn.mail:hover, .btn.mail:active, .btn.mail.btnMenuActive {
  background-color: #61646b;
}

.btn.mail.flip {
  transform: rotateY(180deg);
}

.btn.help {
  background-color: #7E8696;
  background-image: url("../img/info.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
}

.btn.help:hover, .btn.help:active, .btn.help.btnMenuActive {
  background-color: #61646b;
}

.btn.help.flip {
  transform: rotateY(180deg);
}

.btn.more {
  background-color: #7E8696;
  background-image: url("../img/menu.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  width: 3vw;
}

.btn.more:hover, .btn.more:active, .btn.more.btnMenuActive {
  background-color: #61646b;
}

.btn.more.flip {
  transform: rotateY(180deg);
}

.btn.cprt {
  background-color: #7E8696;
  background-image: url("../img/lien-site.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
}

.btn.cprt:hover, .btn.cprt:active, .btn.cprt.btnMenuActive {
  background-color: #61646b;
}

.btn.cprt.flip {
  transform: rotateY(180deg);
}

.btn.courante {
  line-height: 1;
}

/******** Header *********/
.faceToface {
  width: 50%;
  float: left;
}

#logoBloc {
  padding-left: 0.5vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

#logoBloc img {
  max-height: 7vh;
  max-width: 100%;
  height: auto;
}

#titleBloc {
  text-align: right;
}

#titleBloc h1, #titleBloc h2 {
  margin: 0;
  /*text-shadow: 0 0 3px white;*/
  /*Ain't no shadow*/
}

#titleBloc h1 {
  /* font-size: $utils-height / 2; */
  font-size: 1.5em;
  cursor: pointer;
}

#titleBloc h2 {
  /* font-size: $utils-height / 4; */
  font-size: 1.25em;
  font-weight: 400;
}

/********* Maintenance ***********/
.maintenance {
  position: absolute;
  width: 100%;
  top: 8vh;
  box-sizing: border-box;
  padding: 0.5rem;
  font-size: 2em;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 0.25em 0.25em;
  border-top: 0.1vh solid #333;
}

/******** Canvas *********/
.canvasBody {
  display: block;
  height: 100%;
  width: 100%;
}

.canvasBody.zoomed {
  cursor: move;
}

.fleches {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.35s ease;
}

.fleches::after {
  content: '';
  display: block;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  background-color: #58C2E2;
  background-image: url("../img/image-suivante.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.fleches:hover {
  opacity: 1;
}

.fleches.right {
  right: 0;
  margin-right: 1%;
}

.fleches.left {
  transform: rotate(180deg);
  margin-left: 1%;
}

.coupeur {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 48.5%;
  height: 100%;
  width: 1em;
  cursor: move;
  cursor: ew-resize;
  background-color: #3498db;
  background-image: linear-gradient(to right, #fffafb, #ede9e9 50%, #bcbbc6 50%, #9796a1);
}

.coupeur:after {
  content: '';
  display: block;
  position: relative;
  height: 2em;
  width: 2em;
  top: 48%;
  top: calc(50% - 1em);
  margin-left: -0.5em;
  border-radius: 2em;
  background-color: #7E8696;
  background-image: url("../img/fleches_vs.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: background-color 0.5s ease-out;
  overflow: hidden;
  box-sizing: border-box;
}

.coupeur:hover:after {
  background-color: #61646b;
}

#punkt {
  height: 2px;
  width: 2px;
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background: red;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/******** Footer *********/
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vh 0;
  width: 80vw;
  margin-bottom: 0;
  margin-top: -10vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1vw;
}

footer .panel {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  /*padding: 0 0.7vw;*/
  border-right: 0.1em solid #C6C6C5;
}

footer .vues {
  justify-content: flex-end;
  flex-basis: 14.25%;
  padding-right: 0.5em;
}

footer .vues select {
  min-width: 90%;
  max-width: 12.85%;
  font-size: 1.15em;
  font-weight: 700;
  padding-top: 0.10vh;
  padding-bottom: 0.10vh;
  line-height: 1;
}

footer .versus {
  flex-shrink: 0;
  flex-basis: 28%;
}

footer .versus .recto, footer .versus .verso {
  width: auto;
  font-size: 1.15rem;
}

footer.noVersus .versus {
  display: none;
}

footer .calendrier {
  flex-shrink: 0;
  flex-basis: 17.5%;
}

footer .calendrier .courante {
  display: inline-block;
  width: auto;
  text-align: center;
  font-size: 2vh;
}

footer .calendrier button {
  font-size: 1.3em;
}

footer .calendrier button, footer .calendrier .courante {
  vertical-align: middle;
}

footer .zoom {
  justify-content: space-around;
  flex-basis: 22%;
}

footer .zoom button {
  font-size: 1.3em;
}

footer .utils {
  justify-content: space-around;
  flex-basis: 14.25%;
}

footer.noDL .utils {
  padding: 0 1.2vw;
}

footer.noDL .utils .save {
  display: none;
}

footer .copyright {
  border-right: 0 none;
  justify-content: flex-start;
  flex-basis: 4%;
}

footer .copyright .btn {
  margin-left: 0.7vw;
}

/********* popIn *************/
.pop-opaq {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.pop-opaq.fadeIn {
  opacity: 1;
}

.pop-opaq.fadeOut {
  opacity: 0;
}

.pop-opaq .pop-body {
  /*position: absolute;*/
  box-sizing: border-box;
  padding: 1vh;
  height: 80vh;
  width: 80vw;
  max-width: 500px;
  /** Parce que M. Blossier l'a dit : "Le popup est un peu immense du coup, vous le réduisez ? 500px par exemple ?" **/
  /*top: 10vh;
		left: 10vw;*/
  margin: 0 auto;
  margin-top: 10vh;
  background-color: white;
  box-shadow: 0 0 5px black;
  font-size: 1.5em;
  color: black;
  overflow: auto;
}

.pop-opaq .pop-body img {
  max-width: 100%;
  height: auto;
}

.pop-opaq .pop-close {
  position: absolute;
  height: 5vh;
  width: 5vh;
  /*top: 7.5vh;
		right: 8.5vw;*/
  top: 1.5vh;
  right: 1.5vw;
  border-radius: 5vh;
  box-shadow: 0 0 5px grey;
  cursor: pointer;
  background-color: white;
  background-image: url("../img/1448381499_times.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.plus {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  min-width: 25%;
  padding: 1vh 1vw;
  border-radius: 0.5vh;
  opacity: 0;
  transition: opacity 0.35s;
}

.plus.noDL .save {
  display: none;
}

.plus[hidden] {
  display: none;
}

/********* Activity *************/
.spinner {
  z-index: 2000;
  width: 2vw;
  height: 2vw;
  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 2vw;
  -webkit-animation: spinner 400ms linear infinite;
  animation: spinner 400ms linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#header_spinner {
  position: absolute;
  top: 4vh;
  left: 50%;
  margin-left: -1vw;
  margin-top: -1vw;
}

/********* Décompte ***********/
.decompte {
  position: absolute;
  right: 0;
  top: 8vh;
  padding: 0.5rem;
  font-size: 2vh;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 0 0.25em;
  border-top: 0.1vh solid #333;
}

/********* Calendrier ***********/
.taskbar {
  background-color: rgba(28, 28, 27, 0.8);
  border-bottom: 1px solid #9D9C9C;
  border-radius: 0.5em 0.5em 0 0;
  padding: 0.5vh 0.5vw;
  height: 5vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.taskbar .btn {
  min-height: 3vh;
  width: 3vh;
  margin-left: 0.5vw;
}

.taskbar .btn.back {
  background-color: #7E8696;
  background-image: url("../img/fleche_retour.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  min-height: 3vh;
  width: 3vh;
  /** non affiché par défaut, juste pour la grille **/
  visibility: hidden;
}

.taskbar .btn.back:hover, .taskbar .btn.back:active, .taskbar .btn.back.btnMenuActive {
  background-color: #61646b;
}

.taskbar .btn.back.flip {
  transform: rotateY(180deg);
}

.taskbar .btn.move {
  background-color: #7E8696;
  background-image: url("../img/deplacer.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  cursor: move;
  min-height: 3vh;
  width: 3vh;
}

.taskbar .btn.move:hover, .taskbar .btn.move:active, .taskbar .btn.move.btnMenuActive {
  background-color: #61646b;
}

.taskbar .btn.move.flip {
  transform: rotateY(180deg);
}

.taskbar .btn.close {
  background-color: #7E8696;
  background-image: url("../img/fermer.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0;
  border-width: 0;
  min-height: 4vh;
  width: 4vh;
  border-radius: 1vh;
  text-indent: -9999vw;
  opacity: 1;
  transition: background-color 0.5s ease-out;
  min-height: 3vh;
  width: 3vh;
  background-color: #58C2E2;
  border-radius: 50%;
}

.taskbar .btn.close:hover, .taskbar .btn.close:active, .taskbar .btn.close.btnMenuActive {
  background-color: #61646b;
}

.taskbar .btn.close.flip {
  transform: rotateY(180deg);
}

.taskbar .taskbar-title {
  color: #FFFFFF;
  flex: 1 0 auto;
  height: 100%;
  font-size: 1.4em;
  line-height: 3.5vh;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskbar .taskbar-title .btn {
  background-color: #58C2E2;
}

.taskbar .taskbar-title .btn.disabled {
  background-color: #DADAD9;
}

.grille {
  width: 100%;
  height: 90%;
  border-bottom: 5px solid white;
  overflow: auto;
  display: none;
  background-color: rgba(255, 255, 255, 0.9);
  color: #1C1C1B;
}

.grilleHeader {
  position: relative;
  margin-bottom: -5vh;
  height: 5vh;
  line-height: 5vh;
  padding-left: 0.5vw;
  font-weight: bold;
  z-index: 2;
  background: white;
  box-shadow: 0 1px 2px black;
  overflow: hidden;
}

.grilleHeader .back {
  float: right;
  min-height: 4vh;
  margin-top: 0.5vh;
  margin-right: 0.5vw;
}

.grilleBody {
  display: flex;
  /*position: relative;
		top: 0;
		padding-top: 5vh;*/
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  /*overflow: auto;*/
}

.grilleElement {
  flex: 1 1 45%;
  max-width: 45%;
  text-align: center;
  margin: 0.5em 0;
}

.grilleElement img {
  max-width: 100%;
}

#table_calendrier {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  display: none;
}

#table_calendrier .badge {
  background-color: rgba(28, 28, 27, 0.8);
  color: #FFFFFF;
  width: 4.5vh;
  height: 4.5vh;
  line-height: 4.75vh;
  text-align: center;
  font-size: 1.1em;
  margin: 0 auto;
  border-radius: 50%;
  visibility: hidden;
}

#table_calendrier .control-calendrier {
  text-align: center;
  font-size: 1.3em;
}

#table_calendrier .control-calendrier button {
  border: 0;
  border-radius: 1vh;
  width: 5vw;
  background-image: linear-gradient(to bottom, #58C2E2, #58C2E2 40%, #58C2E2 60%, #58C2E2);
}

#table_calendrier .control-calendrier button:hover, #table_calendrier .control-calendrier button:active {
  background-image: linear-gradient(to bottom, #479EB2, #479EB2 40%, #479EB2 60%, #479EB2);
}

#table_calendrier .control-calendrier button:nth-child(1) {
  float: left;
  margin-left: 1%;
}

#table_calendrier .control-calendrier button:nth-child(3) {
  float: right;
  margin-right: 1%;
}

#table_calendrier .control-calendrier:first-child {
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  border-bottom: 1px solid #1C1C1B;
}

#table_calendrier th {
  text-align: center;
  font-size: 0.7em;
  background-color: rgba(28, 28, 27, 0.8);
  color: #FFFFFF;
}

#table_calendrier td {
  font-size: 0.8em;
  height: 6.5vh;
  width: 14%;
  padding: 0.3em 0.6em;
  text-align: right;
  vertical-align: top;
  border: 1px solid #C6C6C5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.1s linear, color 0.1s linear;
  background-color: rgba(255, 255, 255, 0.9);
  color: #1C1C1B;
}

#table_calendrier td.hasPhoto {
  text-decoration: underline;
  font-weight: bold;
}

#table_calendrier td.hasPhoto .badge {
  text-decoration: none;
  font-weight: normal;
}

#table_calendrier td.precedent, #table_calendrier td.prochain {
  color: #878786;
  background-color: rgba(218, 218, 217, 0.8);
}

#table_calendrier td:hover, #table_calendrier td:active {
  background-color: rgba(218, 218, 217, 0.95);
  color: #1C1C1B;
}

#table_calendrier td:hover .badge, #table_calendrier td:active .badge {
  visibility: visible;
}

#container_calendrier {
  width: 33%;
  border-radius: 0.5em;
  font-weight: 400;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.35s;
}

#container_calendrier.pics {
  height: 50%;
}

#container_calendrier.pics .taskbar-title button {
  display: none;
}

#container_calendrier.pics .taskbar-title .date {
  width: 100%;
}

#container_calendrier.pics .back {
  visibility: visible;
}

#container_calendrier.pics .grille {
  display: block;
}

#container_calendrier.cal #table_calendrier {
  display: table;
}

/* Default value */
.progressjs-inner {
  width: 0;
  height: 2px;
  transition: all 0.3s ease-out;
}

.progressjs-progress {
  z-index: 1;
}

.progressjs-percent {
  display: none;
}

.progressjs-overlay {
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 0.2s ease-out;
}

.progressjs-overlay .progressjs-inner {
  height: 100%;
}

.progressjs-show-percent .progressjs-percent {
  width: 70px;
  text-align: center;
  height: 40px;
  position: absolute;
  right: 50%;
  margin-right: -35px;
  top: 50%;
  margin-top: -20px;
  font-size: 30px;
  display: block;
}

.progressjs-radius {
  border-radius: 5px;
}

.progressjs-radius .progressjs-inner {
  border-radius: 5px;
}

.progressjs-opacity {
  opacity: 0.5;
}

.progressjs-opacity .progressjs-percent {
  opacity: 0.5;
}

/* blue theme, like iOS 7 progress bar */
.progressjs-theme-blue .progressjs-inner {
  background-color: #3498db;
}

.progressjs-theme-blue.progressjs-end {
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

/* black theme like black is black no espoir et tout ça */
.progressjs-theme-black .progressjs-inner {
  background-color: #34495e;
}

.progressjs-theme-black.progressjs-end {
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

/**** https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ ****/
input[type=range] {
  -webkit-appearance: none;
  background-color: transparent;
  /* margin: $thumb-height/2 (100-$track-width)/2; */
  width: 60%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  /*width: $track-width;*/
  width: 100%;
  height: 1.25vh;
  cursor: pointer;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  /* @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color); */
  background: white;
  border-radius: 0.67vh;
  /* border: $track-border-width solid $track-border-color; */
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 0 #111, 0px 0px 0 #1e1e1e;
  border-width: 0;
  height: 3.5vh;
  width: 3.5vh;
  border-radius: 50%;
  background-color: #58C2E2;
  background-image: url("../img/loupe.svg"), linear-gradient(to bottom, #58C2E2, #58C2E2 40%, #58C2E2 60%, #58C2E2);
  background-repeat: no-repeat, repeat;
  background-size: 90%, 100%;
  background-position: center center, 0 0;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -1.135vh;
}

input[type=range]:hover::-webkit-slider-thumb {
  background-color: #479EB2;
  background-image: url("../img/loupe.svg"), linear-gradient(to bottom, #479EB2, #479EB2 40%, #479EB2 60%, #479EB2);
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: white;
}

input[type=range]::-moz-range-track {
  /*width: $track-width;*/
  width: 100%;
  height: 1.25vh;
  cursor: pointer;
  -moz-transition: all .2s ease;
  transition: all .2s ease;
  /* @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color); */
  background: white;
  border-radius: 0.67vh;
  /* border: $track-border-width solid $track-border-color; */
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 0 #111, 0px 0px 0 #1e1e1e;
  border-width: 0;
  height: 3.5vh;
  width: 3.5vh;
  border-radius: 50%;
  background-color: #58C2E2;
  background-image: url("../img/loupe.svg"), linear-gradient(to bottom, #58C2E2, #58C2E2 40%, #58C2E2 60%, #58C2E2);
  background-repeat: no-repeat, repeat;
  background-size: 90%, 100%;
  background-position: center center, 0 0;
  cursor: pointer;
}

input[type=range]:hover::-moz-range-thumb {
  background-color: #479EB2;
  background-image: url("../img/loupe.svg"), linear-gradient(to bottom, #479EB2, #479EB2 40%, #479EB2 60%, #479EB2);
}

input[type=range]::-ms-track {
  /*width: $track-width;*/
  width: 100%;
  height: 1.25vh;
  cursor: pointer;
  -ms-transition: all .2s ease;
  transition: all .2s ease;
  background: transparent;
  background-repeat: no-repeat;
  border-color: transparent;
  border-width: 3.5vh 0;
  color: transparent;
  margin-top: 1.4vh;
}

input[type=range]::-ms-fill-lower {
  background: #f2f2f2;
  /* border: $track-border-width solid $track-border-color; */
  border-radius: 1.34vh;
  /* @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color); */
}

input[type=range]::-ms-fill-upper {
  background: white;
  /* border: $track-border-width solid $track-border-color; */
  border-radius: 1.34vh;
  /* @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color); */
}

input[type=range]::-ms-thumb {
  box-shadow: 0 0 0 #111, 0px 0px 0 #1e1e1e;
  border-width: 0;
  height: 3.5vh;
  width: 3.5vh;
  border-radius: 50%;
  background-color: #58C2E2;
  background-image: url("../img/loupe.svg"), linear-gradient(to bottom, #58C2E2, #58C2E2 40%, #58C2E2 60%, #58C2E2);
  background-repeat: no-repeat, repeat;
  background-size: 90%, 100%;
  background-position: center center, 0 0;
  cursor: pointer;
}

input[type=range]:hover::-ms-thumb {
  background-color: #479EB2;
  background-image: url("../img/loupe.svg"), linear-gradient(to bottom, #479EB2, #479EB2 40%, #479EB2 60%, #479EB2);
}

input[type=range]:focus::-ms-fill-lower {
  background: white;
}

input[type=range]:focus::-ms-fill-upper {
  background: white;
}

@media only screen and (max-width: 480px) and (orientation: portrait) {
  #titleBloc h1 {
    font-size: 1.1em;
  }
  #titleBloc h2 {
    font-size: 0.8em;
  }
  .decompte {
    display: none;
  }
  #container_calendrier {
    width: 90%;
  }
  .plus {
    min-width: 50%;
  }
  .taskbar .btn {
    width: 3.5vh;
    min-height: 3.5vh;
  }
  .grille {
    height: 87%;
  }
  .fleches::after {
    height: 1.2em;
    width: 1.2em;
  }
  #header_spinner {
    top: 50%;
    width: 35vw;
    height: 35vw;
    margin-left: -17.5vw;
    margin-top: -17.5vw;
    border-radius: 35vw;
    border-width: 4vw;
  }
  footer {
    height: 16vh;
    margin-top: -20vh;
    flex-wrap: wrap;
  }
  footer .panel {
    height: 50%;
  }
  footer .panel .btn {
    min-height: 5vh;
    width: 5vh;
  }
  footer .panel select.btn {
    min-height: auto;
  }
  footer .vues {
    padding: 0 3vw;
    flex-basis: 30%;
    max-width: 30%;
  }
  footer .vues select {
    min-width: 100%;
    margin-right: 0;
  }
  footer .versus {
    border-right: 0 none;
    flex-grow: 1;
    /**** "si vous avez la possibilité de ne pas afficher le Versus pour le mobile, c'est top" ***/
    display: none;
  }
  footer .versus .changer {
    margin: 0 2vw;
  }
  footer .versus .recto, footer .versus .verso {
    width: auto;
    padding: 0.5vh 1vw;
  }
  footer .calendrier {
    flex-basis: 60%;
    /*}
			&.noVersus .calendrier {*/
    border-right: 0 none;
  }
  footer .calendrier .btn {
    width: 3.5vh;
    min-height: 3.5vh;
  }
  footer .calendrier .courante {
    width: auto;
    margin: 0 1vw;
    padding: 0.5vh 1vw;
  }
  footer .zoom {
    display: none;
  }
  footer .utils {
    flex-basis: auto;
    /*.visible-xs {
					display: block;
					margin: 0 1vw;
				}
				.visible-sm {
					display: none;
				}
			}
			&.noVersus .utils {*/
    flex-basis: 54.25%;
  }
  footer .utils .visible-xs {
    display: none;
  }
  footer .utils .visible-sm {
    display: block;
  }
  footer.noDL .utils .save {
    display: none;
  }
  footer .copyright {
    /*flex-basis: auto;*/
    flex-basis: 13vw;
    justify-content: center;
    border-right: 0 none;
  }
  footer.noDL .copyright {
    flex-basis: 20vw;
  }
}

@media only screen and (min-width: 481px) and (max-width: 700px) and (orientation: landscape) {
  header, footer {
    height: 12vh;
  }
  header {
    margin-bottom: -12vh;
  }
  footer {
    margin-top: -15vh;
    font-size: 3.5vh;
  }
  footer .panel {
    border-right: 1px solid white;
  }
  footer .panel .btn {
    width: 8vh;
    min-height: 8vh;
  }
  footer .panel select.btn {
    min-width: 100%;
  }
  footer .vues {
    flex-basis: 25%;
  }
  footer .versus {
    /*** "si vous avez la possibilité de ne pas afficher le Versus pour le mobile, c'est top" ***/
    display: none;
  }
  footer .versus .changer {
    margin: 0 0.5vw;
  }
  footer .versus .recto, footer .versus .verso {
    width: auto;
    padding: 0.5vh 0.5vw;
  }
  footer .calendrier {
    flex-basis: 35%;
  }
  footer .calendrier .courante {
    width: 12vw;
    margin: 0 0.5vw;
    font-size: 1em;
  }
  footer .zoom {
    display: none;
  }
  footer .utils {
    flex-basis: 30%;
  }
  footer .utils .visible-xs {
    display: none;
  }
  footer .utils .visible-sm {
    display: block;
  }
  footer .copyright {
    flex-basis: auto;
    border-right: 0 none;
  }
  .taskbar .taskbar-title {
    font-size: 1em;
  }
  #logoBloc img {
    max-height: 11vh;
  }
  #titleBloc h1 {
    font-size: 1.1em;
  }
  #titleBloc h2 {
    font-size: 0.8em;
  }
  .decompte {
    display: none;
  }
  .fleches::after {
    height: 2em;
    width: 2em;
  }
  #header_spinner {
    top: 50%;
    width: 25vw;
    height: 25vw;
    margin-left: -12.5vw;
    margin-top: -12.5vw;
    border-radius: 25vw;
    border-width: 2vw;
  }
}

@media only screen and (min-width: 701px) and (max-width: 900px) and (orientation: landscape) {
  header, footer {
    height: 12vh;
  }
  header {
    margin-bottom: -12vh;
  }
  footer {
    margin-top: -15vh;
    font-size: 3.5vh;
  }
  footer .panel {
    border-right: 1px solid white;
  }
  footer .panel .btn {
    width: 8vh;
    min-height: 8vh;
  }
  footer .panel select.btn {
    min-width: 100%;
  }
  footer .vues {
    flex-basis: 25%;
  }
  footer .vues select {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
  }
  footer .versus {
    /*** "si vous avez la possibilité de ne pas afficher le Versus pour le mobile, c'est top" ***/
    display: none;
  }
  footer .versus .changer {
    margin: 0 0.5vw;
  }
  footer .versus .recto, footer .versus .verso {
    width: auto;
    padding: 0.5vh 0.5vw;
  }
  footer .calendrier {
    flex-basis: 35%;
  }
  footer .calendrier .courante {
    width: 12vw;
    margin: 0 0.5vw;
    font-size: 1em;
  }
  footer .zoom {
    display: none;
  }
  footer .utils {
    flex-basis: 25%;
  }
  footer .utils .visible-xs {
    display: none;
  }
  footer .utils .visible-sm {
    display: block;
  }
  footer .copyright {
    flex-basis: auto;
    border-right: 0 none;
  }
  .taskbar .taskbar-title {
    font-size: 1em;
  }
  #logoBloc img {
    max-height: 11vh;
  }
  #titleBloc h1 {
    font-size: 1.1em;
  }
  #titleBloc h2 {
    font-size: 0.8em;
  }
  .decompte {
    display: none;
  }
  .fleches::after {
    height: 2em;
    width: 2em;
  }
  #header_spinner {
    top: 50%;
    width: 25vw;
    height: 25vw;
    margin-left: -12.5vw;
    margin-top: -12.5vw;
    border-radius: 25vw;
    border-width: 2vw;
  }
}

/** On a besoin de re-définir des petits trucs pour tablette finalement. **/
@media only screen and (min-width: 701px) and (max-width: 900px) and (orientation: portrait) {
  #titleBloc h1 {
    font-size: 3vh;
  }
  #container_calendrier {
    width: 70%;
  }
  footer {
    width: 95vw;
  }
  footer .panel .btn {
    width: 3.5vh;
    min-height: 3.5vh;
  }
  footer .panel select.btn {
    min-width: 90%;
  }
  footer.noVersus .calendrier {
    flex-basis: 20%;
  }
  footer .versus {
    flex-basis: 38%;
  }
  footer .versus .recto, footer .versus .verso {
    width: auto;
  }
  footer .calendrier {
    flex-basis: 27%;
  }
  footer .calendrier .courante {
    width: auto;
  }
  footer.noVersus .calendrier {
    flex-basis: 30vw;
  }
  footer .zoom {
    display: none;
  }
  footer .utils {
    flex-basis: auto;
    padding: 0 0.5vw;
  }
  footer .utils .visible-xs {
    display: block;
  }
  footer .utils .visible-sm, footer .utils .help {
    display: none;
  }
  footer.noVersus .utils {
    flex-basis: 25vw;
    padding: 0 0.7vw;
  }
  footer.noVersus .utils .visible-xs {
    display: none;
  }
  footer.noVersus .utils .visible-sm, footer.noVersus .utils .help {
    display: block;
  }
  footer.noDL.noVersus .utils {
    flex-basis: 20vw;
    padding: 0 0.5vw;
  }
  footer.noDL.noVersus .utils .save {
    display: none;
  }
  footer .copyright {
    flex-basis: auto;
  }
  #header_spinner {
    top: 50%;
    width: 35vw;
    height: 35vw;
    margin-left: -17.5vw;
    margin-top: -17.5vw;
    border-radius: 35vw;
    border-width: 4vw;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1100px) and (orientation: landscape) {
  #titleBloc h1 {
    font-size: 1.25em;
  }
  #titleBloc h2 {
    font-size: 1.1em;
  }
  #container_calendrier {
    width: 40%;
  }
  footer {
    width: 95vw;
  }
  footer .panel .btn {
    width: 3.5vh;
    min-height: 3.5vh;
  }
  footer .panel select.btn {
    min-width: 90%;
  }
  footer .versus {
    flex-basis: 29%;
  }
  footer .versus .recto, footer .versus .verso {
    width: auto;
    font-size: 2.3vh;
  }
  footer .calendrier {
    flex-basis: 18%;
  }
  footer .calendrier .courante {
    width: auto;
    font-size: 2.3vh;
  }
  footer .utils {
    flex-basis: 12.5%;
    flex-shrink: 0;
  }
  footer .utils .visible-xs {
    display: none;
  }
  footer .utils .visible-sm {
    display: block;
  }
  footer.noDL .utils {
    flex-basis: 11%;
    padding: 0;
  }
  footer .copyright {
    flex-basis: auto;
    flex-shrink: 0;
    padding-right: 0.25em;
  }
  #header_spinner {
    top: 50%;
    width: 25vw;
    height: 25vw;
    margin-left: -12.5vw;
    margin-top: -12.5vw;
    border-radius: 25vw;
    border-width: 2vw;
  }
}

@media (min-width: 1101px) {
  .help {
    display: none;
  }
  /* Basé sur https://github.com/codrops/TooltipStylesInspiration/blob/master/css/tooltip-classic.css */
  .tooltip {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 100%;
    text-align: center;
    box-shadow: -5px -5px 15px rgba(48, 54, 61, 0.2);
    background: #2a3035;
    border-radius: 5px;
    opacity: 0;
    cursor: default;
    pointer-events: none;
    width: auto;
    height: 2em;
    margin: 0 0 20px -140px;
    line-height: 2em;
    padding: 0 1em;
    transform-origin: 50% calc(100% + 2em);
    transform: rotate3d(0, 0, 1, 15deg);
    transition: opacity 0.2s, transform 0.2s;
    transition-timing-function: ease, cubic-bezier(0.17, 0.67, 0.4, 1.39);
  }
  .tooltip.shown {
    pointer-events: auto;
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  }
  .tooltip::before, .tooltip::after {
    content: '';
    display: table;
  }
  .tooltip::after {
    clear: both;
    top: 100%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-top-color: #2a3035;
    border-width: 10px;
    margin-left: -10px;
  }
  .tooltip .tooltip-text {
    display: block;
    color: #fff;
  }
  .tooltip.upsideDown {
    margin: 20px 0 0 -140px;
    transform: rotate3d(0, 0, 1, -15deg);
  }
  .tooltip.upsideDown.shown {
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  }
  .tooltip.upsideDown::after {
    top: -20px;
    border-top-color: transparent;
    border-bottom-color: #2a3035;
  }
}

@media (min-width: 1101px) {
  footer .utils .visible-xs {
    display: none;
  }
  footer .utils .visible-sm {
    display: block;
  }
  #header_spinner {
    top: 0vh;
    width: 5.5vh;
    height: 5.5vh;
    margin-left: -2.75vh;
    margin-top: 0.5vh;
    border-radius: 5.5vh;
    border-width: 1vh;
  }
}
/*# sourceMappingURL=styles.css.map */